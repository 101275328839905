export const AgbEN = () => {

    return (
      
      <div> 
          <h1>GTC - General Terms and Conditions</h1>
          <>
            <br/>
            <h3>1. GENERAL INFORMATION</h3>
            <p>Welcome to Crypto Cocktail Party, owned and operated by CryptoBartl (CryptoBartl, “the Company“, “we”, “us”, or “our”). These Terms of Use (“Terms”) govern your access to 
            the CryptoBartl website(s), our APIs, mobile applications (“App”) and any live support, software, tools, features or functionality provided on or 
            and their use in connection with our services; This includes, but is not limited to, using our Services to view, explore, view, and create tokens and NFTs 
            as well as using our tools at your discretion to engage directly with others to mint, buy, sell tokens and NFTs on public blockchains 
            or transfer (collectively: “Service”). 
            By definition, a “token” is a digital unit that exists on a blockchain and represents a value or right. It functions similarly to physical money in the digital world and can be used 
            for a variety of purposes: for example, as a medium of exchange, as proof of ownership of digital or physical goods, or as proof of authorization for certain services.
            “NFT” as used in these Terms means a non-fungible token or similar digital item stored on a blockchain (such as the Ethereum blockchain) that 
            is implemented and uses smart contracts to link or otherwise associate with certain content or data.
            A cryptocurrency “wallet“ is a device, physical medium, program or an online service which stores the public and/or private keys for cryptocurrency transactions. 
            In addition to this basic function of storing the keys, a cryptocurrency wallet more often offers the functionality of encrypting and/or signing information. Signing can 
            for example result in executing a smart contract, a cryptocurrency transaction, identification, or legally signing a 'document'.
            </p>
            <p>For the purposes of these Terms, “User”, “you” and “your” refer to you as a user of the Service. If you are using the Service on behalf of a company or other 
            entity, “you” includes you and such entity, and you represent and warrant that (a) you are an authorized representative of the 
            legal entity with the authority to bind the entity to these Terms, and (b) you agree to these Terms on behalf of the Company.</p>
            <p>These Terms of Use (the "Terms of Use") govern the access and use of the websites operating under the domain name www.crypto-cocktailparty.com (the "Sites") 
            and hosted under each of the subdomains or dependent websites, as well as the content and services that the owner of the websites provides to its users (the "Users"), 
            together with the privacy and cookies policy relating to the handling of users' personal data, the conditions for the 
            mentioned websites (the “Terms”).</p>
            <p>Access to the websites or their use by the user necessarily and without reservation implies the knowledge and acceptance of the General Terms and Conditions. 
            The user is therefore recommended to read them carefully each time they access the websites mentioned. If the User respects the clauses set out in these Terms of Use 
            not accepted, he must refrain from accessing and/or using the services and/or content made available on the websites and leave them.</p>
            <p>In order to use the services offered on the websites, the user must be of legal age. Therefore, by accepting the following general terms and conditions, the user declares that 
            that he is of legal age and has the necessary legal capacity to use the services offered on the websites.</p>
            <p>In this context, the user is in any case responsible for the accuracy and truthfulness of the data that he provides to CryptoBartl via the website.</p>
            Because we have a growing number of services, we sometimes need to provide additional terms and conditions for certain services (and such services apply 
            as part of the “Service” hereinafter and are also subject to these Terms). The additional terms and conditions applicable to the relevant service 
            become part of your agreement with us when you use these services. In the event of a conflict between these Terms and 
            any additional applicable terms that we provide for a particular Service, those additional terms will take precedence for that particular Service.
            <br/>
            <p>CryptoBartl reserves the right to change or amend these Terms at any time and at our sole discretion. If we make any material changes to these Terms,
            We will make reasonable efforts to provide notice of such changes, such as by notifying you on the Service or by updating the
            “Last Updated” date at the top of these Terms. By continuing to access or use the Service, you confirm your acceptance of any revised terms 
            Terms and all terms and conditions incorporated therein by reference, which apply as of the date these Terms are updated. It is your sole responsibility to 
            to review the Terms from time to time to notice any such changes and to ensure that you understand the Terms that apply when you access the Service 
            access or use it.</p>
          </>
          <>
            <br/>
            <h3>2. USE OF THE SITE</h3>
            <p>The user undertakes not to use the websites for activities that violate the law, morals or public order and, in general, the use in 
            to perform in accordance with the terms and conditions set out in these Terms of Use. He also undertakes not to engage in advertising or commercial activities, 
            by sending messages under a false identity.</p>
            <p>The Company acts solely as controller for the Websites in its capacity as an information service provider and is not responsible for the 
            content that users send or publish contrary to these general conditions, the user being solely responsible for its truthfulness and legality 
            is responsible for the same.</p>
            <p>The Company may interrupt the User's use of the Websites and terminate the relationship with the User immediately if there is any use 
            of the websites or any of the services offered therein, which may be considered a breach of these Terms of Use.</p>
            <p>The Company reserves the right to decide at any time on the continuity of the information society services that it offers through its websites.</p>
            <p>In addition, the user assumes that the company can, if necessary, inform administrative or judicial authorities about facts, 
            which could constitute an illegal act without the user having to be informed in advance.</p>
          </>
          <>
            <br/>
            <h3>3. ACCESSING THE SERVICE</h3>
            <p>As is generally the case with Web3, your blockchain address acts as your identity with CryptoBartl. Accordingly, you need a blockchain address and a third-party wallet, 
            to access the service. Your account on the Service (“Account”) will be linked to your blockchain address.</p>
            <p>Your Account on CryptoBartl will be associated with your linked blockchain address and display the Tokens/NFTs for that blockchain address (and, if applicable, any content 
              associated with such Token/NFTs). By using your wallet in connection with the Service, you agree that you are using that wallet under the terms and conditions of the applicable 
              provider of the wallet. Wallets are not operated by, maintained by, or affiliated with CryptoBartl, and CryptoBartl does not have custody or control over the contents of 
              your wallet and has no ability to retrieve or transfer its contents. CryptoBartl accepts no responsibility for, or liability to you, in connection with your use of a wallet 
              and makes no representations or warranties regarding how the Service will operate with any specific wallet. You are solely responsible for keeping your wallet secure and you 
              should never share your wallet credentials or seed phrase with anyone. If you discover an issue related to your wallet, please contact your wallet provider. Likewise, you are 
              solely responsible for your Account and any associated wallet and we are not liable for any acts or omissions by you in connection with your Account or as a result of your 
              Account or wallet being compromised. You agree to immediately notify us if you discover or otherwise suspect any security issues related to the Service or your Account.</p>
           <p>Your access and use of the Service may be interrupted from time to time for any of several reasons, including, without limitation, the malfunction of equipment, periodic 
            updating, maintenance, or repair of the Service, geographic restrictions, potential violation of these Terms, or other actions that CryptoBartl, in its sole discretion, 
            may elect to take. You understand that CryptoBartl, in its sole discretion, may disable your Account and/or reassign your username or associated url.</p>
            <p>We require all users to be at least 18 years old. If you are at least 13 years old but under 18 years old, you may only use CryptoBartl through a parent or guardian’s 
              Account and with their approval and oversight. That account holder is responsible for your actions using the Account. It is prohibited to use our Service if you 
              are under 13 years old.</p>
          </>
          <>
            <br/>
            <h3>4. NAVIGATION PROTECTION</h3>
            <p>When connecting to the Internet, in addition to the basic aspects of computer protection, it is advisable to take into account the following precautions: <br/>
            - Use current browser versions.<br/>
            - When accessing the protected area, ensure that you are accessing the correct page and use encryption protocols to 
            to ensure the integrity and confidentiality of the information provided.<br/>
            - Only visit well-known websites.<br/>
            - Do not accept the execution of programs that download automatically if you do not trust the sources from which you download the data.<br/>
            - When using public or shared computers, make sure the automatic password reminder option is not enabled.<br/>
            </p>
          </>
          <>
            <br/>
            <h3>5. INTELLECTUAL AND INDUSTRIAL PROPERTY</h3>
            <p>The entirety of the websites: texts, images, brands, graphics, logos, buttons, software files, color combinations as well as the structure, selection, arrangement and presentation 
            its contents are protected by intellectual and industrial property laws and belong to CryptoBartl as the author of the entire work. The reproduction, 
            distribution, public communication and conversion are prohibited except for personal and private use.</p>
            <p>The Company does not guarantee that the Content is accurate or error-free or that the user's free use of the Content will not infringe the rights of third parties. 
            The proper or improper use of this site and its contents is the responsibility of the user.</p>
            <p>Similarly, the reproduction, distribution, copying, transmission or further distribution of the information contained on the site, in whole or in part, is prohibited. 
            Regardless of their purpose and the means used, prohibited without prior authorization from the Company.</p>
            <p>CryptoBartl has no ownership, custody or control over coins, tokens, NFTs or the smart contracts used by third parties. 
              The creators of these tokens, NFTs or smart contracts are solely responsible for their operation and functionality.</p>
          </>
          <>
            <br/>
            <h3>6. LINKS</h3>
            <p>The site may contain links to third party sites. Sites belonging to third parties have not been reviewed nor are they under the control of the Company. 
            In any case, the Company cannot be held responsible for the content of these websites or for the measures taken in relation to data protection 
            or the processing of personal data.</p>
            <p>The Company recommends that you carefully read the terms of use and privacy policies of these websites.</p>
            <p>If you are interested in activating a link to one of the Company's pages, you must communicate this and express consent to do so. The Company reserves 
              the right to refuse to activate links to its website.</p>
          </>
          <>
            <br/>
            <h3>7. LIABILITIES</h3>
            <p>The Company is not responsible, directly or indirectly, for:</p>
            <ul>
              <li>The quality of the service, the speed of access, the proper functioning, the availability and the continuity of the operation of the site.</li>
              <li>Any damage that may occur to the user's devices through the use of the site.</li>
              <li>Cases in which a third party accesses the messages in violation of the established security measures or uses them to send computer viruses.</li>
              <li>Inadequacies and defects of any kind in the content transmitted, distributed, stored or made available.</li>
            </ul>
            <br/>
            <p>By way of illustration and without limitation, the User is responsible for:</p>
            <ul>
              <li>the content entered by him, in particular the data and information that he transmits to the company via the websites.</li>
              <li>the carrying out of unlawful, infringing, harmful and/or detrimental actions of any kind.</li>
              <li>the legality, reliability and usefulness of the content that users submit through the use of the site or the services offered therein, as well as their truthfulness or accuracy. The Company does not control 
                users' use of the Site nor does it guarantee that they will be in compliance with the provisions of this legal notice is made.</li>
            </ul>
          </>
      	  <> 
            <br/>
            <h3>8. USER CONDUCT</h3>
            <p>You agree that you will not violate any law, contract, intellectual property or other third-party right, and that you are solely responsible for your conduct and content, 
              in connection with using the Service. You also agree that you will not:</p>
              <ul>
                  <li>Use or attempt to use another user’s Account without authorization from such user;</li>
                  <li>Pose as another person or entity, or use a wallet to engage in a transaction on CryptoBartl that is owned or controlled, in whole or in part, by any other person;</li>
                  <li>Access the Service from a different blockchain address if we’ve blocked any of your other blockchain addresses from accessing the Service, unless you have our written
                   permission first;</li>
                  <li>Bypass or ignore instructions that control access to the Service, including attempting to circumvent any rate limiting systems, directing traffic through 
                    multiple IP addresses, or otherwise obfuscating the source of traffic you send to CryptoBartl;</li>
                  <li>Use our Service, including our APIs, in any way that conflicts with our developer policies;</li>
                  <li>Use our Service for commercial purposes inconsistent with these Terms or any other instructions;</li>
                  <li>Use any data mining, robot, spider, crawler, scraper, script, browser extension, offline reader, or other automated means or interface not authorized by us to access 
                    the Service, extract data, or otherwise interfere with or modify the rendering of Service pages or functionality;</li>
                  <li>Reverse engineer, duplicate, decompile, disassemble, or decode any aspect of the Service, or do anything that might discover source code or bypass or circumvent 
                    measures employed to prevent or limit access to any service, area, or code of the Service;</li>
                  <li>Use the Service, directly or indirectly, for or in connection with money laundering, terrorist financing, or other illicit financial activity, or in any way in 
                    connection with the violation of any law or regulation that applies to you or to CryptoBartl;</li>
                  <li>Use the Service with the proceeds of unlawful activity or with a wallet used to engage in unlawful activity;</li>
              </ul>
              <p>Finally, by using the Service, you understand the importance of DYOR – doing your own research. You bear full responsibility for verifying the authenticity, legitimacy, 
                identity, and other details about any tokens/NFT, collection, or account that you view or otherwise interact with in conjunction with our Service. We make no claims, guarantees 
                or recommendations about the identity, legitimacy, or authenticity of any tokens/NFT, collection, or account on the Service.</p>
          </>
          <>
            <br/>
            <h3>9. JURISDICTION</h3>
            <p>For any type of dispute arising from the use of the services offered or the content of the websites, the parties submit through the 
            Acceptance of the clauses set out in these Terms of Use by the competent courts of Germany.</p>
          </>
          <>
            <br/>
            <h3>10. DISCLAIMERS, RIGHT OF WITHDRAWAL</h3>
            <p>YOUR ACCESS TO AND USE OF THE SERVICE IS AT YOUR OWN RISK. YOU UNDERSTAND AND AGREE THAT THE SERVICE IS PROVIDED ON “AS IT IS” AND “AS AVAILABLE” BASIS AND CRYPTOBARTL 
              EXPRESSLY DISCLAIMS WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED. CRYPTOBARTL (AND ITS SUPPLIERS) MAKE NO WARRANTY OR REPRESENTATION AND DISCLAIM ALL 
              RESPONSIBILITY FOR WHETHER THE SERVICE: (A) WILL MEET YOUR REQUIREMENTS; (B) WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; OR (C) WILL BE 
              ACCURATE, RELIABLE, COMPLETE, LEGAL, OR SAFE. CRYPTOBARTL DISCLAIMS ALL OTHER WARRANTIES OR CONDITIONS, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES 
              OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. CRYPTOBARTL WILL NOT BE LIABLE FOR ANY LOSS OF ANY KIND FROM ANY ACTION 
              TAKEN OR TAKEN IN RELIANCE ON MATERIAL OR INFORMATION, CONTAINED ON THE SERVICE. WHILE CRYPTOBARTL ATTEMPTS TO MAKE YOUR ACCESS TO AND USE OF THE SERVICE SAFE, CRYPTOBARTL 
              CANNOT AND DOES NOT REPRESENT OR WARRANT THAT THE SERVICE, CONTENT, CONTENT LINKED TO OR ASSOCIATED WITH ANY TOKENS/NFTS, OR ANY TOKENS/NFTS YOU INTERACT WITH USING OUR SERVICE OR OUR 
              SERVICE PROVIDERS’ SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. WE CANNOT GUARANTEE THE SECURITY OF ANY DATA THAT YOU DISCLOSE ONLINE. NO ADVICE OR INFORMATION, 
              WHETHER ORAL OR OBTAINED FROM THE CRYPTOBARTL PARTIES OR THROUGH THE SERVICE, WILL CREATE ANY WARRANTY OR REPRESENTATION NOT EXPRESSLY MADE HEREIN. YOU ACCEPT THE INHERENT 
              SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET AND WILL NOT HOLD CRYPTOBARTL RESPONSIBLE FOR ANY BREACH OF SECURITY.
            </p>
            <p>WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS AND TAKE NO RESPONSIBILITY FOR, AND WILL NOT BE LIABLE TO YOU FOR, ANY USE OF OR LOSS OF USE OF TOKENS, NFTS, 
             CONTENT, AND/OR CONTENT LINKED TO OR ASSOCIATED WITH TOKENS/NFTS, INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES, OR CLAIMS ARISING FROM: (A) USER ERROR, INCORRECTLY CONSTRUCTED 
              TRANSACTIONS, OR MISTYPED ADDRESSES; (B) SERVER FAILURE OR DATA LOSS; (C) UNAUTHORIZED ACCESS OR USE; (D) ANY UNAUTHORIZED THIRD-PARTY ACTIVITIES, INCLUDING WITHOUT LIMITATION 
              THE USE OF VIRUSES, PHISHING, BRUTEFORCING OR OTHER MEANS OF ATTACK AGAINST THE SERVICE, TOKENS OR NFTS.</p>
            <p>NO CRYPTOBARTL PARTY IS RESPONSIBLE OR LIABLE FOR ANY SUSTAINED LOSSES OR INJURY DUE TO VULNERABILITY OR ANY KIND OF FAILURE, ABNORMAL BEHAVIOR OF SOFTWARE (E.G., WALLET, 
              SMART CONTRACT), BLOCKCHAINS OR ANY OTHER FEATURES OF THE TOKENS/NFTS. NO CRYPTOBARTL PARTY IS RESPONSIBLE FOR LOSSES OR INJURY DUE TO LATE REPORTS BY DEVELOPERS OR REPRESENTATIVES 
              (OR NO REPORT AT ALL) OF ANY ISSUES WITH THE BLOCKCHAIN SUPPORTING THE TOKENS/NFTS, INCLUDING FORKS, TECHNICAL NODE ISSUES OR ANY OTHER ISSUES HAVING LOSSES OR INJURY AS A RESULT.</p>              <p>Some jurisdictions do not allow the exclusion of implied warranties in contracts with consumers, so the above exclusion may not apply to you.</p>
          </>
          <>
            <br/>
            <h3>11. ASSUMPTION OF RiSK</h3>
            <p>You accept and acknowledge:</p>
            <ul>
              <li>The value of a Token/NFT is subjective. Prices of Tokens/NFTs are subject to volatility and fluctuations in the price of cryptocurrency can also materially and adversely affect 
                Token/NFT prices. You acknowledge that you fully understand this subjectivity and volatility and that you may lose money.</li>
                <li>You understand that you are responsible for any fees related to use of the Service, including for actions you take on the blockchain, regardless of whether a successful
                   transaction occurs, and that such fees are final and irreversible.</li>
                <li>A lack of use or public interest in the creation and development of distributed ecosystems could negatively impact the development of those ecosystems and related 
                  applications, and could therefore also negatively impact the potential utility of Token/NFTs.</li>
                <li>The regulatory regime governing blockchain technologies, non-fungible tokens, cryptocurrency, and other crypto-based items is uncertain, and new regulations or 
                  policies may materially adversely affect the development of the Service and the utility of Tokens/NFTs</li>
                <li>You are solely responsible for determining what, if any, taxes apply to your transactions and to withhold, collect, report, and remit the correct amounts of taxes to the 
                  appropriate tax authorities. CryptoBartl is not responsible for determining, withholding, collecting, reporting, or remitting any taxes that apply to your Tokens/NFTs or 
                  the sale/purchase of your Tokens/NFTs.</li>
                <li>We do not have ownership or control of the smart contracts deployed by third parties, and are not responsible and make no guarantees regarding their operation and functionality.</li>
                <li>We do not control the public blockchains that you are interacting with and we do not control certain smart contracts and protocols that may be integral to your ability to 
                  complete transactions on these public blockchains. Additionally, blockchain transactions are irreversible and CryptoBartl has no ability to reverse any transactions on the blockchain.</li>
                <li>There are risks associated with using Internet and blockchain based products, including, but not limited to, the risk associated with hardware, software, and Internet
                   connections, the risk of malicious software introduction, and the risk that third parties may obtain unauthorized access to your third-party wallet or Account. 
                   You accept and acknowledge that CryptoBartl will not be responsible for any communication failures, disruptions, errors, distortions or delays you may experience 
                   when using the Service or any Blockchain network, however caused.</li>
                <li>The Service relies on third-party platforms and/or vendors. If we are unable to maintain a good relationship with such platform providers and/or vendors; if the terms 
                  and conditions or pricing of such platform providers and/or vendors change; if we violate or cannot comply with the terms and conditions of such platforms and/or vendors; 
                  or if any of such platforms and/or vendors loses market share or falls out of favor or is unavailable for a prolonged period of time, access to and use of the Service will 
                  suffer.</li>
            </ul>
          </>
          <>
            <br/>
            <h3>12. LIMITATIONS OF LIABILITY</h3>
            <p>TO THE FULLEST EXTENT PERMITTED BY LAW, YOU AGREE THAT IN NO EVENT WILL CRYPTOBARTL OR ITS SERVICE PROVIDERS BE LIABLE TO YOU OR ANY THIRD PARTY (A) FOR ANY LOST PROFIT 
              OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES ARISING FROM THESE TERMS OR THE SERVICE, PRODUCTS OR THIRD-PARTY SITES AND PRODUCTS, 
              OR FOR ANY DAMAGES RELATED TO LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, OR LOSS OF DATA, AND WHETHER CAUSED 
              BY STRICT LIABILITY OR TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE AND EVEN IF CRYPTOBARTL OR ITS SERVICE PROVIDERS HAVE BEEN ADVISED 
              OF THE POSSIBILITY OF SUCH DAMAGES; OR (B) FOR ANY OTHER CLAIM, DEMAND, OR DAMAGES WHATSOEVER RESULTING FROM OR ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OF THE 
              DELIVERY, USE, OR PERFORMANCE OF THE SERVICE. ACCESS TO, AND USE OF, THE SERVICE, PRODUCTS OR THIRD-PARTY SITES, AND PRODUCTS ARE AT YOUR OWN DISCRETION AND RISK, AND 
              YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA RESULTING THEREFROM.</p>
              <p>NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, IN NO EVENT SHALL THE MAXIMUM AGGREGATE LIABILITY OF CRYPTOBARTL ARISING OUT OF OR IN ANY WAY RELATED TO THESE 
              TERMS, THE ACCESS TO AND USE OF THE SERVICE, CONTENT, TOKENS/NFTS, OR ANY CRYPTOBARTL PRODUCTS OR SERVICES EXCEED THE GREATER OF (A) $100 OR (B) THE AMOUNT RECEIVED BY CRYPTOBARTL 
              FOR ITS SERVICE TO YOU DIRECTLY RELATING TO THE ITEMS THAT ARE THE SUBJECT OF THE CLAIM. THE FOREGOING LIMITATIONS WILL APPLY EVEN IF THE ABOVE STATED REMEDY 
              FAILS OF ITS ESSENTIAL PURPOSE.
              Some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to you. 
              Some jurisdictions also limit disclaimers or limitations of liability for personal injury from consumer products, so this limitation may not apply to personal injury claims.</p>
          </>
          <>
            <br/>
            <h3>13. PRIVACY POLICY</h3>
            <p>Please refer to our Privacy Policy for information about how we collect, use, and share information from and/or about you ("Your Information"). By submitting Your 
              Information through our Service, you agree to the terms of our Privacy Policy and you expressly consent to the collection, use, and disclosure of Your Information 
              in accordance with the Privacy Policy.</p>
          </>
          <>
            <br/>
            <h3>14. APPLICABLE LANGUAGE CLAUSE</h3>
            <p>The general terms and conditions were written in both English and German. If there are any discrepancies between the two versions, 
            The German version takes precedence when interpreting the conditions.</p>
          </>

      </div>
    )
  };

  export const AgbDE = () => {

    //aus 1 entfernt (Open.S.)
    //Bitte lesen Sie diese Nutzungsbedingungen sorgfältig durch, da sie wichtige Informationen enthalten und sich auf Ihre gesetzlichen Rechte auswirken. 
    //WIE IN ABSCHNITT 16 UNTEN BESCHRIEBEN, ENTHALTEN SIE EINE OBLIGATORISCHE SCHIEDSVEREINBARUNG UND EINEN VERZICHT AUF SAMMELKLAGEN, DIE (MIT EINGESCHRÄNKTEN AUSNAHMEN) ERFORDERN, 
    //DASS JEGLICHE STREITIGKEITEN ZWISCHEN UNS DURCH EIN INDIVIDUELLES SCHIEDSVERFAHREN UND NICHT DURCH EINEN RICHTER ODER EINE JURY VOR GERICHT GELÖST WERDEN.</p>
    return (
      
      <div> 
          <h1>AGB - Allgemeine Geschäftsbedingungen</h1>
          <>
            <h3>1. ALLGEMEINE INFORMATIONEN</h3>
            <p>Willkommen bei Crypto-Cocktailparty, Eigentum und Betreiber von CryptoBartl (CryptoBartl, das Unternehmen, „wir“, „uns“ oder „unser“). Diese Nutzungsbedingungen („Bedingungen“) regeln Ihren Zugriff auf 
            die CryptoBartl-Website(s), unsere APIs, mobilen Anwendungen („App“) und jeglichen Live-Support, Software, Tools, Features oder Funktionalitäten, die auf oder bereitgestellt werden, 
            und deren Nutzung im Zusammenhang mit unseren Dienstleistungen; Dazu gehört unter anderem die Nutzung unserer Dienste zum Anzeigen, Erkunden, Anzeigen und Erstellen von Tokens und NFTs 
            sowie die Nutzung unserer Tools nach eigenem Ermessen, um direkt mit anderen in Kontakt zu treten, um Tokens und NFTs auf öffentlichen Blockchains zu prägen, zu kaufen, zu verkaufen 
            oder zu übertragen (gemeinsam: „ Service"). 
            Ein „Token“ ist laut Definition eine digitale Einheit, die auf einer Blockchain existiert und einen Wert oder ein Recht repräsentiert. Er funktioniert ähnlich wie physisches Geld in der 
            digitalen Welt und kann für verschiedenste Zwecke eingesetzt werden: etwa als Tauschmittel, als Nachweis für den Besitz von digitalen oder physischen Gütern oder als Berechtigungsnachweis für bestimmte Dienstleistungen.
            „NFT“ bezeichnet in diesen Bedingungen einen nicht fungiblen Token oder ein ähnliches digitales Element, das auf einer Blockchain (wie der Ethereum-Blockchain) 
            implementiert ist und Smart Contracts verwendet, um bestimmte Inhalte oder Daten zu verknüpfen oder anderweitig damit zu verknüpfen.
            Ein „Wallet“ (Geldbeutel) ist ein Gerät, physisches Medium, Programm oder ein Online-Dienst, der die öffentlichen und/oder privaten Schlüssel für Kryptowährungstransaktionen speichert. Zusätzlich zu dieser Grundfunktion 
            des Speicherns der Schlüssel bietet ein Kryptowährungs-Wallet häufiger die Funktionalität des Verschlüsselns und/oder Signierens von Informationen. Die Unterzeichnung kann beispielsweise zur Ausführung eines Smart Contracts, 
            einer Kryptowährungstransaktion, einer Identifizierung oder zur rechtsgültigen Unterzeichnung eines „Dokuments“ führen.
            </p>
            <p>Für die Zwecke dieser Bedingungen beziehen sich „Benutzer“, „Sie“ und „Ihr“ auf Sie als Benutzer des Dienstes. Wenn Sie den Dienst im Namen eines Unternehmens oder einer anderen 
            juristischen Person nutzen, umfasst „Sie“ Sie und diese juristische Person, und Sie sichern zu und garantieren, dass (a) Sie ein autorisierter Vertreter der 
            juristischen Person sind, der befugt ist, die juristische Person an diese Bedingungen zu binden und (b) Sie stimmen diesen Bedingungen im Namen des Unternehmens zu.</p>
            <p>Diese Nutzungsbedingungen (die "Nutzungsbedingungen") regulieren den Zugang und die Nutzung der Websites, die unter dem Domainnamen www.crypto-cocktailparty.com (die "Websites") 
            und unter jeder der Subdomains oder abhängigen Webseiten gehostet werden, sowie die Inhalte und Dienstleistungen, die der Eigentümer der Websites stellen seinen Nutzern (den "Nutzern") 
            zur Verfügung, und legen zusammen mit der Datenschutz- und Cookie-Politik, die sich auf die Handhabung der persönlichen Daten der Nutzer bezieht, die Bedingungen für die 
            genannten Websites fest (die "Bedingungen").</p>
            <p>Der Zugang zu den Webseiten oder deren Nutzung durch den Nutzer setzt notwendigerweise und ohne Vorbehalt die Kenntnis und Annahme der Allgemeinen Geschäftsbedingungen voraus. 
            Daher wird dem Nutzer empfohlen, diese bei jedem Zugriff auf die genannten Websites sorgfältig zu lesen. Wenn der Nutzer die in diesen Nutzungsbedingungen festgelegten Klauseln 
            nicht akzeptiert, muss er auf den Zugriff und/oder die Nutzung der auf den Websites zur Verfügung gestellten Dienstleistungen und/oder Inhalte verzichten und diese verlassen.</p>
            <p>Um die auf den Websites angebotenen Dienste nutzen zu können, muss der Nutzer volljährig sein. Daher erklärt der User durch die Annahme der folgenden Allgemeinen Geschäftsbedingungen, 
            dass er volljährig ist und die erforderliche Geschäftsfähigkeit besitzt, um die auf den Websites angebotenen Dienste in Anspruch zu nehmen.</p>
            <p>In diesem Zusammenhang ist der Nutzer in jedem Fall für die Richtigkeit und Wahrhaftigkeit der Daten verantwortlich, die er CryptoBartl über die Website zur Verfügung stellt.</p>
            Da wir über eine wachsende Anzahl von Diensten verfügen, müssen wir manchmal zusätzliche Bedingungen für bestimmte Dienste bereitstellen (und solche Dienste gelten als Teil des „Dienstes“ im Folgenden und unterliegen ebenfalls diesen Bedingungen). Die zusätzlichen Geschäftsbedingungen, die für den betreffenden Dienst verfügbar sind, werden dann Bestandteil Ihrer Vereinbarung mit uns, wenn Sie diese Dienste nutzen. Im Falle eines Konflikts zwischen diesen Bedingungen und etwaigen zusätzlichen anwendbaren Bedingungen, die wir für einen bestimmten Dienst bereitstellen, haben diese zusätzlichen Bedingungen für diesen bestimmten Dienst Vorrang.
            <br/>
            <p>CryptoBartl behält sich das Recht vor, diese Bedingungen jederzeit und nach unserem alleinigen Ermessen zu ändern oder zu ergänzen. Wenn wir wesentliche Änderungen an diesen Bedingungen vornehmen,
            werden wir angemessene Anstrengungen unternehmen, um über solche Änderungen zu informieren, beispielsweise durch Benachrichtigung über den Dienst oder durch Aktualisierung des
            Datums „Letzte Aktualisierung“ am Anfang dieser Bedingungen. Indem Sie weiterhin auf den Dienst zugreifen oder ihn nutzen, bestätigen Sie Ihr Einverständnis mit den überarbeiteten 
            Bedingungen und allen darin durch Verweis enthaltenen Bedingungen, die ab dem Datum der Aktualisierung dieser Bedingungen gelten. Es liegt in Ihrer alleinigen Verantwortung, 
            die Bedingungen von Zeit zu Zeit zu überprüfen, um solche Änderungen anzuzeigen und sicherzustellen, dass Sie die Bedingungen verstehen, die gelten, wenn Sie auf den Dienst 
            zugreifen oder ihn nutzen.</p>
          </>
          <>
            <br/>
            <h3>2. NUTZUNG DER WEBSITE</h3>
            <p>Der Nutzer verpflichtet sich, die Webseiten nicht für Aktivitäten zu nutzen, die gegen die Gesetze, die Moral oder die öffentliche Ordnung verstoßen, und im Allgemeinen die Nutzung in 
            Übereinstimmung mit den in diesen Nutzungsbedingungen festgelegten Bedingungen auszuführen. Er verpflichtet sich auch, keine Werbung oder kommerzielle Aktivitäten zu betreiben, 
            indem er Nachrichten unter falscher Identität verschickt.</p>
            <p>Das Unternehmen handelt ausschließlich als Verantwortlicher für die Websites in seiner Eigenschaft als Anbieter eines Informationsdienstes und ist nicht verantwortlich für die 
            Inhalte, die die Nutzer entgegen den vorliegenden allgemeinen Bedingungen senden oder veröffentlichen, wobei der Nutzer allein für die Wahrhaftigkeit und Rechtmäßigkeit 
            derselben verantwortlich ist.</p>
            <p>Das Unternehmen kann die Nutzung der Websites durch den Nutzer unterbrechen und die Beziehung mit dem Nutzer sofort beenden, wenn es eine Nutzung 
            der Websites oder einer der darin angebotenen Dienstleistungen feststellt, die als Verstoß gegen die vorliegenden Nutzungsbedingungen angesehen werden kann.</p>
            <p>Das Unternehmen behält sich das Recht vor, jederzeit über die Kontinuität der Dienste der Informationsgesellschaft zu entscheiden, die es über seine Websites anbietet.</p>
            <p>Darüber hinaus geht der Nutzer davon aus, dass das Unternehmen gegebenenfalls Verwaltungs- oder Justizbehörden über Tatsachen informieren kann, 
            die eine rechtswidrige Handlung darstellen könnten, ohne dass der Nutzer vorher darüber informiert werden muss.</p>
          </>
          <>
            <br/>
            <h3>3. ZUGRIFF AUF DEM DIENST</h3>
            <p>Wie generell bei Web3 fungiert Ihre Blockchain-Adresse bei CryptoBartl als Ihre Identität. Dementsprechend benötigen Sie eine Blockchain-Adresse und ein Drittanbieter-Wallet, 
            um auf den Dienst zuzugreifen. Ihr Konto beim Dienst („Konto“) wird mit Ihrer Blockchain-Adresse verknüpft.</p>
            <p>Ihr Konto bei CryptoBartl wird mit Ihrer verknüpften Blockchain-Adresse verknüpft und zeigt die Tokens und NFTs für diese Blockchain-Adresse an 
            (und gegebenenfalls alle mit diesen NFTs verbundenen Inhalte). Durch die Nutzung Ihres Wallets in Verbindung mit dem Service erklären Sie sich damit einverstanden, 
            dass Sie dieses Wallet gemäß den Geschäftsbedingungen des jeweiligen Anbieters des Wallets nutzen. Wallets werden nicht von CryptoBartl betrieben, verwaltet oder sind mit 
            CryptoBartl verbunden, und CryptoBartl hat weder das Sorgerecht noch die Kontrolle über den Inhalt Ihres Wallets und ist nicht in der Lage, seinen Inhalt abzurufen oder zu 
            übertragen. CryptoBartl übernimmt keine Verantwortung oder Haftung Ihnen gegenüber im Zusammenhang mit Ihrer Nutzung einer Wallet und gibt keine Zusicherungen oder 
            Gewährleistungen hinsichtlich der Funktionsweise des Dienstes mit einer bestimmten Wallet. Sie allein sind für die Sicherheit Ihres Wallets verantwortlich und sollten 
            Ihre Wallet-Zugangsdaten oder Seed-Phrase niemals an Dritte weitergeben. Wenn Sie ein Problem mit Ihrem Wallet feststellen, wenden Sie sich bitte an Ihren Wallet-Anbieter. 
            Ebenso sind Sie allein für Ihr Konto und alle damit verbundenen Wallets verantwortlich und wir haften nicht für Handlungen oder Unterlassungen Ihrerseits im Zusammenhang mit 
            Ihrem Konto oder als Folge der Gefährdung Ihres Kontos oder Wallets. Sie erklären sich damit einverstanden, uns unverzüglich zu benachrichtigen, wenn Sie Sicherheitsprobleme 
            im Zusammenhang mit dem Dienst oder Ihrem Konto feststellen oder anderweitig vermuten</p>
           <p>Ihr Zugang und Ihre Nutzung des Dienstes können von Zeit zu Zeit aus verschiedenen Gründen unterbrochen werden, einschließlich, aber nicht beschränkt auf, Fehlfunktionen 
            von Geräten, regelmäßige Aktualisierungen, Wartung oder Reparatur des Dienstes, geografische Beschränkungen, potenzielle Verstöße gegen diese Bedingungen, 
            oder andere Maßnahmen, die CryptoBartl nach eigenem Ermessen ergreifen kann. Sie verstehen, dass CryptoBartl nach eigenem Ermessen Ihr Konto deaktivieren und/oder Ihren 
            Benutzernamen oder die zugehörige URL neu zuweisen kann.</p>
            <p>Wir verlangen von allen Nutzern, dass sie mindestens 18 Jahre alt sind. Wenn Sie mindestens 13, aber unter 18 Jahre alt sind, dürfen Sie CryptoBartl nur über das Konto 
              eines Elternteils oder Erziehungsberechtigten und mit dessen Zustimmung und Aufsicht nutzen. Der Kontoinhaber ist für Ihre Handlungen unter Verwendung des Kontos verantwortlich. 
              Es ist verboten, unseren Service zu nutzen, wenn Sie unter 13 Jahre alt sind.</p>
          </>
          <>
            <br/>
            <h3>4. NAVIGATIONSSCHUTZ</h3>
            <p>
            Bei der Verbindung mit dem Internet ist es zweckmäßig, neben den grundlegenden Aspekten des Computerschutzes die folgenden Vorsichtsmaßnahmen zu berücksichtigen: <br/>
            - Verwenden Sie aktuelle Browser-Versionen.<br/>
            - Vergewissern Sie sich beim Zugriff auf den geschützten Bereich, dass der Zugriff auf die richtige Seite erfolgt, und verwenden Sie Verschlüsselungsprotokolle, um 
            die Integrität und Vertraulichkeit der bereitgestellten Informationen zu gewährleisten.<br/>
            - Besuchen Sie nur bekannte Websites.<br/>
            - Akzeptieren Sie nicht die Ausführung von Programmen, die automatisch heruntergeladen werden, wenn Sie den Quellen, von denen Sie die Daten herunterladen, nicht vertrauen.<br/>
            - Vergewissern Sie sich bei der Nutzung von öffentlichen oder gemeinsam mit Dritten genutzten Computern, dass die Option zur automatischen Kennworterinnerung nicht aktiviert ist.<br/>
            </p>
          </>
          <>
            <br/>
            <h3>5. GEISTIGES UND GEWERBLICHES EIGENTUM</h3>
            <p>Die Gesamtheit der Webseiten: Texte, Bilder, Marken, Grafiken, Logos, Schaltflächen, Softwaredateien, Farbkombinationen sowie die Struktur, Auswahl, Anordnung und Präsentation 
            ihrer Inhalte sind durch Gesetze zum Schutz des geistigen und gewerblichen Eigentums geschützt und gehören CryptoBartl als Urheber des Gesamtwerks. Die Vervielfältigung, 
            der Vertrieb, die öffentliche Kommunikation und die Umwandlung sind verboten, außer für den persönlichen und privaten Gebrauch.</p>
            <p>Das Unternehmen garantiert nicht, dass die Inhalte korrekt oder fehlerfrei sind oder dass die freie Nutzung der Inhalte durch den Benutzer nicht die Rechte Dritter verletzt. 
            Die ordnungsgemäße oder unsachgemäße Nutzung dieser Seite und ihrer Inhalte liegt in der Verantwortung des Nutzers.</p>
            <p>Ebenso ist die Vervielfältigung, die Weitergabe, das Kopieren, die Übertragung oder die Weiterverbreitung der auf der Seite enthaltenen Informationen, ganz oder teilweise, 
            unabhängig von ihrem Zweck und den dafür verwendeten Mitteln, ohne vorherige Genehmigung durch das Unternehmen verboten.</p>
            <p>CryptoBartl hat kein Eigentum, kein Sorgerecht oder keine Kontrolle über Coins, Tokens, NFTs oder die von Dritten eingesetzten Smart Contracts. 
              Die Ersteller dieser NFTs oder Smart Contracts sind allein für deren Betrieb und Funktionalität verantwortlich.</p>
          </>
          <>
            <br/>
            <h3>6. LINKS</h3>
            <p>Die Seite kann Links zu Seiten Dritter enthalten. Seiten, die zu Dritten gehören, wurden weder überprüft noch unterliegen sie der Kontrolle des Unternehmens. 
            In jedem Fall kann das Unternehmen nicht für den Inhalt dieser Websites oder für die Maßnahmen verantwortlich gemacht werden, die in Bezug auf den Datenschutz 
            oder die Verarbeitung personenbezogener Daten getroffen werden.</p>
            <p>Das Unternehmen empfiehlt, die Nutzungsbedingungen und Datenschutzrichtlinien dieser Websites sorgfältig zu lesen.</p>
            <p>Wenn Sie daran interessiert sind, einen Link zu einer der Seiten des Unternehmens zu aktivieren, müssen Sie dies mitteilen und die ausdrückliche Zustimmung zur 
            Einrichtung des Links einholen. Das Unternehmen behält sich das Recht vor, die Aktivierung von Links zu seiner Website abzulehnen.</p>
          </>
          <>
            <br/>
            <h3>7. VERBINDLICHKEITEN</h3>
            <p>Das Unternehmen ist weder direkt noch indirekt verantwortlich für:</p>
            <ul>
              <li>Die Qualität des Dienstes, die Zugriffsgeschwindigkeit, das ordnungsgemäße Funktionieren, die Verfügbarkeit und die Kontinuität des Betriebs der Seite.</li>
              <li>Jegliche Schäden, die durch die Nutzung der Seite an den Geräten des Nutzers entstehen können.</li>
              <li>Fälle, in denen ein Dritter unter Verletzung der festgelegten Sicherheitsmaßnahmen auf die Nachrichten zugreift oder sie zum Versand von Computerviren verwendet.</li>
              <li>Unzulänglichkeiten und Mängel jeglicher Art in den übermittelten, verbreiteten, gespeicherten oder zur Verfügung gestellten Inhalten.</li>
              
            </ul>
            <br/>
            <p>Zur Veranschaulichung und ohne Einschränkung ist der Nutzer verantwortlich für:</p>
            <ul>
              <li>die von ihm eingegebenen Inhalte, insbesondere die Daten und Informationen, die er dem Unternehmen über die Websites übermittelt.</li>
              <li>die Durchführung von rechtswidrigen, rechtsverletzenden, schädlichen und/oder nachteiligen Handlungen jeglicher Art.</li>
              <li>die Rechtmäßigkeit, Zuverlässigkeit und Nützlichkeit der Inhalte, die die Nutzer durch die Nutzung der Seite oder der darauf angebotenen Dienste übermitteln, sowie deren Wahrheitsgehalt oder Genauigkeit. Das Unternehmen kontrolliert nicht die Nutzung der Seite durch die Nutzer und garantiert auch nicht, dass diese in 
                Übereinstimmung mit den Bestimmungen des vorliegenden rechtlichen Hinweises erfolgt.</li>
            </ul>
          </>
      	  <> 
            <br/>
            <h3>8. BENUTZERVERHALTEN</h3>
            <p>Sie erklären sich damit einverstanden, dass Sie keine Gesetze, Verträge, geistigen Eigentumsrechte oder andere Rechte Dritter verletzen und 
              dass Sie allein für Ihr Verhalten und Ihre Inhalte im Zusammenhang mit der Nutzung des Dienstes verantwortlich sind. Sie stimmen außerdem zu, 
              dass Sie Folgendes nicht tun werden:</p>
              <ul>
                  <li>Das Konto eines anderen Benutzers ohne Genehmigung dieses Benutzers zu nutzen oder dies zu versuchen;</li>
                  <li>Sich als eine andere natürliche oder juristische Person ausgeben oder eine Wallet verwenden, um eine Transaktion auf CryptoBartl durchzuführen, die ganz oder 
                  teilweise einer anderen Person gehört oder von dieser kontrolliert wird;</li>
                  <li>Greifen Sie von einer anderen Blockchain-Adresse auf den Dienst zu, wenn wir eine Ihrer anderen Blockchain-Adressen für den Zugriff auf den Dienst blockiert haben, 
                  es sei denn, Sie haben zuvor unsere schriftliche Genehmigung erhalten;</li>
                  <li>Umgehen oder Ignorieren von Anweisungen, die den Zugriff auf den Dienst steuern, einschließlich des Versuchs, Ratenbegrenzungssysteme zu umgehen, den Datenverkehr 
                  über mehrere IP-Adressen zu leiten oder die Quelle des Datenverkehrs, den Sie an CryptoBartl senden, auf andere Weise zu verschleiern;</li>
                  <li>Nutzen Sie unseren Service, einschließlich unserer APIs, in einer Weise, die im Widerspruch zu unseren Entwicklerrichtlinien steht.</li>
                  <li>Nutzung unseres Dienstes für kommerzielle Zwecke, die im Widerspruch zu diesen Bedingungen oder anderen Anweisungen stehen;</li>
                  <li>Verwenden Sie Data Mining, Roboter, Spider, Crawler, Scraper, Skripte, Browsererweiterungen, Offline-Reader oder andere automatisierte Mittel oder Schnittstellen, 
                  die nicht von uns autorisiert wurden, um auf den Dienst zuzugreifen, Daten zu extrahieren oder die Darstellung der Dienstseiten auf andere Weise zu stören oder zu 
                  ändern oder Funktionalität;</li>
                  <li>jeglichen Aspekt des Dienstes zurückzuentwickeln, zu duplizieren, zu dekompilieren, zu disassemblieren oder zu dekodieren oder irgendetwas zu tun, das den Quellcode 
                  entdecken oder Maßnahmen umgehen oder umgehen könnte, die eingesetzt werden, um den Zugriff auf Dienste, Bereiche oder Codes des Dienstes zu verhindern oder einzuschränken;
                  Den Dienst verkaufen oder weiterverkaufen oder versuchen, CryptoBartl-Gebührensysteme zu umgehen;</li>
                  <li>Den Dienst direkt oder indirekt für oder im Zusammenhang mit Geldwäsche, Terrorismusfinanzierung oder anderen illegalen Finanzaktivitäten oder in irgendeiner Weise im 
                    Zusammenhang mit der Verletzung von Gesetzen oder Vorschriften, die für Sie oder CryptoBartl gelten, nutzen;</li>
                  <li>Nutzen Sie den Dienst mit den Erlösen aus rechtswidrigen Aktivitäten oder mit einem Wallet, der für rechtswidrige Aktivitäten verwendet wird;</li>
              </ul>
              <p>Schließlich verstehen Sie durch die Nutzung des Dienstes, wie wichtig es ist, dass Sie Ihre eigene Recherche durchführen. Sie tragen die volle Verantwortung für die Überprüfung
                 der Authentizität, Legitimität, Identität und anderer Details zu allen Tokens und NFTs, Sammlungen oder Konten, die Sie im Zusammenhang mit unserem Service ansehen oder mit 
                 denen Sie anderweitig interagieren. Wir machen keine Ansprüche, Garantien oder Empfehlungen hinsichtlich der Identität, Legitimität oder Authentizität von Tokens, NFTs, 
                 Sammlungen oder Konten im Dienst.</p>
          </>
          <>
            <br/>
            <h3>9. GERICHTSSTAND</h3>
            <p>Für jede Art von Streitigkeiten, die sich aus der Nutzung der angebotenen Dienste oder dem Inhalt der Websites ergeben, unterwerfen sich die Parteien durch die 
              Annahme der in diesen Nutzungsbedingungen festgelegten Klauseln den zuständigen Gerichten von Deutschland.</p>
          </>
          <>
            <br/>
            <h3>10. HAFTUNGSAUSSCHLUSS, WIDERRUFSRECHT</h3>
            <p>IHR ZUGRIFF AUF UND DIE NUTZUNG DES DIENSTES ERFOLGT AUF EIGENE GEFAHR. SIE VERSTEHEN UND STIMMEN ZU, DASS DER DIENST „WIE BESEHEN“ UND „WIE VERFÜGBAR“ BEREITGESTELLT WIRD, 
              UND CRYPTOBARTL LEHNT AUSDRÜCKLICH GEWÄHRLEISTUNGEN ODER BEDINGUNGEN JEGLICHER ART, WEDER AUSDRÜCKLICH NOCH STILLSCHWEIGEND, AB. CRYPTOBARTL (UND SEINE LIEFERANTEN) 
              ÜBERNIMMT KEINE GEWÄHRLEISTUNG ODER ZUSICHERUNG UND SCHLIESST JEGLICHE VERANTWORTUNG DAFÜR AUS, OB DER DIENST: (A) IHRE ANFORDERUNGEN ERFÜLLT; (B) UNUNTERBROCHEN, PÜNKTLICH, 
              SICHER ODER FEHLERFREI VERFÜGBAR SIND; ODER (C) RICHTIG, ZUVERLÄSSIG, VOLLSTÄNDIG, RECHTLICH ODER SICHER SIND. CRYPTOBARTL SCHLIESST ALLE ANDEREN AUSDRÜCKLICHEN ODER 
              STILLSCHWEIGENDEN GEWÄHRLEISTUNGEN ODER BEDINGUNGEN AUS, EINSCHLIESSLICH, OHNE BESCHRÄNKUNG, STILLSCHWEIGENDER GEWÄHRLEISTUNGEN ODER BEDINGUNGEN DER MARKTGÄNGIGKEIT, 
              EIGNUNG FÜR EINEN BESTIMMTEN ZWECK, TITEL UND NICHTVERLETZUNG. CRYPTOBARTL ÜBERNIMMT KEINE HAFTUNG FÜR VERLUSTE JEGLICHER ART, DIE AUS HANDLUNGEN ENTSTEHEN, DIE IM VERTRAUEN 
              AUF MATERIALIEN ODER INFORMATIONEN, DIE IM DIENST ENTHALTEN SIND, ERGRIFFEN WERDEN. WÄHREND CRYPTOBARTL VERSUCHT, IHREN ZUGRIFF AUF DEN DIENST UND IHRE NUTZUNG SICHER ZU MACHEN, 
              KANN CRYPTOBARTL NICHT ZUSICHERN ODER GEWÄHRLEISTEN, DASS DER DIENST, DIE INHALTE, DIE MIT JEGLICHEN TOKENS/NFTS ODER JEGLICHEN TOKENS/NFTS, MIT DENEN SIE INTERAGIEREN, NUTZUNG UNSERER 
              DIENSTLEISTUNGEN ODER UNSERER DIENSTLEISTUNGEN SIND Die Server der Anbieter sind frei von Viren oder anderen schädlichen Komponenten. WIR KÖNNEN DIE SICHERHEIT DER DATEN, 
              DIE SIE ONLINE OFFENLEGEN, NICHT GARANTIEREN. KEINE RATSCHLÄGE ODER INFORMATIONEN, OB MÜNDLICH ODER VON DEN CRYPTOBARTL-PARTEIEN ODER ÜBER DEN DIENST ERHALTEN, BEGRÜNDEN 
              EINE GARANTIE ODER ZUSICHERUNG, DIE HIERIN NICHT AUSDRÜCKLICH GEMACHT WIRD. SIE AKZEPTIEREN DIE INHABERTEN SICHERHEITSRISIKEN, DIE MIT DER ONLINE-BEREITSTELLUNG VON 
              INFORMATIONEN UND DEM HANDEL ÜBER DAS INTERNET VERBUNDEN SIND, UND ÜBERNEHMEN CRYPTOBARTL NICHT FÜR SICHERHEITSVERLETZUNGEN VERANTWORTLICH.</p>
              <p>Wir sind Ihnen gegenüber nicht verantwortlich oder haftbar für Verluste und übernehmen keine Verantwortung und Haftung Ihnen gegenüber für die Nutzung oder den 
                Nutzungsverlust von Tokens, NFTs, Inhalten und/oder Inhalten, die mit Tokens/NFTs verknüpft oder damit verbunden sind. EINSCHLIESSLICH, ABER NICHT BESCHRÄNKT AUF VERLUSTE, 
                SCHÄDEN ODER ANSPRÜCHE, DIE AUS FOLGENDEN ENTSTEHEN: (A) BENUTZERFEHLER, FALSCH AUFGEFÜHRTE TRANSAKTIONEN ODER FEHLERHAFTE ADRESSEN; (B) SERVERAUSFALL ODER DATENVERLUST; 
                (C) UNBEFUGTER ZUGRIFF ODER UNBEFUGTE NUTZUNG; (D) JEGLICHE UNBEFUGTEN AKTIVITÄTEN DRITTER, EINSCHLIESSLICH, OHNE BESCHRÄNKUNG, DER VERWENDUNG VON VIREN, PHISHING, 
                BRUTEFORCING ODER ANDEREN ANGRIFFSMITTELN GEGEN DEN DIENST, TOKENS ODER NFTS.</p>
              <p>KEINE CRYPTOBARTL-PARTEI IST VERANTWORTLICH ODER HAFTBAR FÜR NACHHALTIGE VERLUSTE ODER VERLETZUNGEN AUFGRUND VON SCHWACHSTELLEN ODER AUSFÄLLEN JEGLICHER ART, 
                ANORMALEM VERHALTEN DER SOFTWARE (Z. B. WALLET, SMART CONTRACT), BLOCKCHAINS ODER ANDEREN FUNKTIONEN DES TOKENS/NFTS. KEINE CRYPTOBARTL-PARTEI IST VERANTWORTLICH FÜR 
                VERLUSTE ODER VERLETZUNGEN, DIE AUFGRUND SPÄTER MELDUNGEN VON ENTWICKLER ODER VERTRETERN (ODER KEINER MELDUNG) ÜBER PROBLEME MIT DER BLOCKCHAIN, DIE DIE TOKENS ODER NFTS 
                UNTERSTÜTZT, EINSCHLIESSLICH GABELN, TECHNISCHER KNOTENPROBLEME ODER ANDERER PROBLEME MIT VERLUSTEN ODER VERLETZUNGEN ALS A ERGEBNIS.</p>
              <p>In einigen Gerichtsbarkeiten ist der Ausschluss stillschweigender Gewährleistungen in Verträgen mit Verbrauchern nicht zulässig, sodass der oben genannte Ausschluss möglicherweise nicht auf Sie zutrifft.</p>
          </>
          <>
            <br/>
            <h3>11. RISIKOÜBERNAHME</h3>
            <p>Sie akzeptieren und einwilligen Folgendes an:</p>
            <ul>
              <li>Der Wert eines Tokens/NFT ist subjektiv. Die Preise von Tokens/NFTs unterliegen Volatilität und Schwankungen im Preis der Kryptowährung können sich ebenfalls erheblich 
                und nachteilig auf die Token/NFT-Preise auswirken. Sie erkennen an, dass Sie diese Subjektivität und Volatilität vollständig verstehen und dass Sie möglicherweise Geld verlieren.</li>
                <li>Sie verstehen, dass Sie für alle Gebühren im Zusammenhang mit der Nutzung des Dienstes verantwortlich sind, einschließlich der von Ihnen in der Blockchain durchgeführten 
                  Aktionen, unabhängig davon, ob eine erfolgreiche Transaktion erfolgt, und dass diese Gebühren endgültig und unumkehrbar sind.</li>
                <li>Eine mangelnde Nutzung oder ein mangelndes öffentliches Interesse an der Schaffung und Entwicklung verteilter Ökosysteme könnte sich negativ auf die Entwicklung dieser 
                  Ökosysteme und damit verbundener Anwendungen und damit auch negativ auf den potenziellen Nutzen von Tokens/NFTs auswirken.</li>
                <li>Das Regulierungssystem für Blockchain-Technologien, Tokens, nicht fungible Token, Kryptowährung und andere kryptobasierte Elemente ist ungewiss, und neue Vorschriften oder 
                  Richtlinien können die Entwicklung des Dienstes und den Nutzen von Tokens/NFTs erheblich beeinträchtigen.</li>
                <li>Der Kauf von Artikeln im Zusammenhang mit Inhalten, die von Dritten über Peer-to-Peer-Transaktionen erstellt wurden, birgt Risiken, einschließlich, 
                  aber nicht beschränkt auf, das Risiko des Kaufs gefälschter Artikel, falsch gekennzeichneter Artikel, Artikel, die anfällig für den Verfall von Metadaten sind, und Artikel 
                  mit Smart Contracts mit Fehlern und Gegenständen, die möglicherweise nicht mehr übertragbar sind. Sie sichern zu und garantieren, dass Sie ausreichende Nachforschungen 
                  angestellt haben, bevor Sie Entscheidungen zum Verkauf, Erwerb, zur Übertragung oder sonstigen Interaktion mit Tokens, NFTs oder Konten/Sammlungen treffen.</li>
                <li>Wir haben kein Eigentum oder keine Kontrolle über die von Dritten eingesetzten Smart Contracts und sind nicht verantwortlich und geben keine Garantien für deren Betrieb und Funktionalität.</li>
                <li>Wir kontrollieren nicht die öffentlichen Blockchains, mit denen Sie interagieren, und wir kontrollieren nicht bestimmte Smart Contracts und Protokolle, 
                  die für Ihre Fähigkeit, Transaktionen auf diesen öffentlichen Blockchains abzuschließen, von wesentlicher Bedeutung sein können. Darüber hinaus sind 
                  Blockchain-Transaktionen irreversibel und CryptoBartl hat keine Möglichkeit, Transaktionen auf der Blockchain rückgängig zu machen.</li>
                <li>Mit der Nutzung von Internet- und Blockchain-basierten Produkten sind Risiken verbunden, einschließlich, aber nicht beschränkt auf, das Risiko im Zusammenhang mit 
                  Hardware, Software und Internetverbindungen, das Risiko der Einführung schädlicher Software und das Risiko, dass Dritte unbefugten Zugriff auf Ihre Daten erhalten 
                  Wallet oder Konto eines Drittanbieters. Sie akzeptieren und erkennen an, dass CryptoBartl nicht für Kommunikationsausfälle, Störungen, Fehler, Verzerrungen oder 
                  Verzögerungen verantwortlich ist, die bei der Nutzung des Dienstes oder eines Blockchain-Netzwerks auftreten können, unabhängig von der Ursache.</li>
                <li>Der Dienst ist auf Plattformen und/oder Anbieter Dritter angewiesen. Wenn wir nicht in der Lage sind, eine gute Beziehung zu solchen Plattformanbietern 
                  und/oder Anbietern aufrechtzuerhalten; wenn sich die Geschäftsbedingungen oder Preise dieser Plattformanbieter und/oder Verkäufer ändern; wenn wir gegen die 
                  Geschäftsbedingungen dieser Plattformen und/oder Anbieter verstoßen oder diese nicht einhalten können; oder wenn eine dieser Plattformen und/oder Anbieter 
                  Marktanteile verliert, in Ungnade fällt oder über einen längeren Zeitraum nicht verfügbar ist, werden der Zugriff auf den Dienst und die Nutzung des Dienstes 
                  beeinträchtigt.</li>
            </ul>
          </>
          <>
            <br/>
            <h3>12. HAFTUNGSBESCHRÄNKUNG</h3>
            <p>SOWEIT GESETZLICH ZULÄSSIG, ERKLÄREN SIE SICH DAMIT EINVERSTANDEN, DASS CRYPTOBARTL ODER SEINE DIENSTLEISTER IHNEN ODER DRITTEN GEGENÜBER (A) IN KEINEM FALL HAFTBAR SIND 
              FÜR ENTGANGENEN GEWINN ODER INDIREKTE, FOLGESCHÄDEN, EXEMPLARISCHE, NEBENSCHÄDEN, BESONDERE ODER STRAFSCHÄDEN FÜR SCHÄDEN, DIE SICH AUS DIESEN BEDINGUNGEN ODER DEN 
              DIENSTLEISTUNGEN, PRODUKTEN ODER WEBSITES UND PRODUKTEN VON DRITTANBIETERN ERGEBEN, ODER FÜR SCHÄDEN IM ZUSAMMENHANG MIT UMSATZVERLUST, ENTGANGENEM GEWINN, 
              VERLUST VON GESCHÄFTEN ODER ERWARTETEN EINSPARUNGEN, NUTZUNGSVERLUST, VERLUST DES FIRMENWERTS ODER VERLUST VON DATEN , UND OB DIES DURCH GEFÄHRLICHE HAFTUNG ODER 
              unerlaubte Handlung (EINSCHLIESSLICH FAHRLÄSSIGKEIT), VERTRAGSVERLETZUNG ODER ANDERWEITIG VERURSACHT WURDE, AUCH WENN VORHERSEHBAR UND AUCH WENN CRYPTOBARTL ODER SEINE 
              DIENSTLEISTER ÜBER DIE MÖGLICHKEIT SOLCHER SCHÄDEN INFORMIERT WURDEN; ODER (B) FÜR JEGLICHE ANDERE ANSPRÜCHE, FORDERUNGEN ODER SCHÄDEN JEGLICHER ART, DIE SICH AUS 
              ODER IN ZUSAMMENHANG MIT DIESEN BEDINGUNGEN FÜR DIE LIEFERUNG, NUTZUNG ODER LEISTUNG DES DIENSTES ERGEBEN. Der Zugriff auf und die Nutzung des Dienstes, der Produkte 
              oder der Websites und Produkte Dritter erfolgt nach Ihrem eigenen Ermessen und auf Ihr eigenes Risiko. Sie tragen die alleinige Verantwortung für etwaige Schäden an 
              Ihrem Computersystem oder mobilen Gerät oder für daraus resultierende Datenverluste.</p>
              <p>UNGEACHTET ALLER HIERIN ENTHALTENEN GEGENTEILEN ÜBERSTEIGT IN KEINEM FALL DIE MAXIMALE GESAMTHAFTUNG VON CRYPTOBARTL, DIE SICH AUS DIESEN BEDINGUNGEN, DEM ZUGRIFF AUF
              UND DIE NUTZUNG DES DIENSTES, INHALTS, NFTS ODER JEGLICHER CRYPTOBARTL-PRODUKTE ODER -DIENSTLEISTUNGEN ERGEBEN ODER IN IRGENDEINER WEISE IM ZUSAMMENHANG MIT IHNEN ENTSTEHT
              Der höhere Betrag von (A) 100 US-Dollar oder (B) dem Betrag, den CryptoBartl für seine Dienste an Sie erhalten hat, die sich direkt auf die Artikel beziehen, 
              die Gegenstand des Anspruchs sind. DIE VORSTEHENDEN EINSCHRÄNKUNGEN GELTEN AUCH WENN DIE OBEN GENANNTEN RECHTSMITTEL IHREN WESENTLICHEN ZWECK NICHT ERFÜLLEN.</p>
          </>
          <>
            <br/>
            <h3>13. DATENSCHUTZERKLÄRUNG</h3>
            <p>Bitte beachten Sie unsere Datenschutzrichtlinie für Informationen darüber, wie wir Informationen von und/oder über Sie („Ihre Informationen“) sammeln, verwenden und weitergeben. 
              Durch die Übermittlung Ihrer Daten über unseren Service stimmen Sie den Bedingungen unserer Datenschutzrichtlinie zu und stimmen der Erfassung, Nutzung und Offenlegung 
              Ihrer Daten gemäß der Datenschutzrichtlinie ausdrücklich zu.</p>
          </>
          <>
            <br/>
            <h3>14. GELTENDE SPRACHKLAUSEL</h3>
            <p>Die Allgemeinen Geschäftsbedingungen wurden sowohl in englischer als auch in deutscher Sprache verfasst. Sollten Unstimmigkeiten zwischen den beiden Fassungen auftreten, 
              hat die deutsche Fassung Vorrang bei der Auslegung der Bedingungen.</p>
          </>
          
      </div>
    )
  };