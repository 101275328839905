import '../TableDesign.css'
import '../App.css'
import React, {useState} from 'react';

import {
    HiOutlineHome,
    HiOutlineHomeModern,
    HiEnvelope,
    HiOutlineCurrencyDollar,
    HiOutlineTrophy,
    HiOutlineUserGroup,
    HiBarsArrowUp,
    HiArrowRightOnRectangle,
  } from "react-icons/hi2";

import { DatenschutzEN, DatenschutzDE } from '../legalComponents/datenschutzComp';
import { AgbEN, AgbDE } from '../legalComponents/abgComp';
import { CookiesPolicyEN, CookiesPolicyDE } from '../legalComponents/cookiesConsentComp';
import { ImpressumEN, ImpressumDE } from '../legalComponents/impressumComp';


//useEffect hook tells our app to do something after rendering
function Legals()
{
  
  //for collapsing
  const [openNavigationBar, setOPenNavigationBar] = useState(true);
  const [openImpressumEN, setopenImpressumEN] = useState(false);
  const [openImpressumDE, setopenImpressumDE] = useState(false);
  const [openDatenschutzEN, setopenDatenschutzEN] = useState(false);
  const [openDatenschutzDE, setopenDatenschutzDE] = useState(false);
  const [openCookiesPolicyEN, setopenCookiesPolicyEN] = useState(false);
  const [openCookiesPolicyDE, setopenCookiesPolicyDE] = useState(false);
  const [openAGBEN, setopenAGBEN] = useState(false);
  const [openAGBDE, setopenAGBDE] = useState(false);

  const toggle_NavigationBar = () => {
    setOPenNavigationBar(!openNavigationBar);
  };
  const toggle_setopenImpressumEN = () => {
    setopenImpressumEN(!openImpressumEN);
  };
  const toggle_setopenImpressumDE = () => {
    setopenImpressumDE(!openImpressumDE);
  };
  const toggle_setopenDatenschutzEN = () => {
    setopenDatenschutzEN(!openDatenschutzEN);
  };
  const toggle_setopenDatenschutzDE = () => {
    setopenDatenschutzDE(!openDatenschutzDE);
  };
  const toggle_setopenCookiesPolicyEN = () => {
    setopenCookiesPolicyEN(!openCookiesPolicyEN);
  };
  const toggle_setopenCookiesPolicyDE = () => {
    setopenCookiesPolicyDE(!openCookiesPolicyDE);
  };
  const toggle_setopenAGBEN = () => {
    setopenAGBEN(!openAGBEN);
  };
  const toggle_setopenAGBDE = () => {
    setopenAGBDE(!openAGBDE);
  };



  //in return is where we are rendering our data
  return (

    <main>
      <div id="divHomepage" className='homepageDesignLeft'>       
        {openNavigationBar && (
            <div className="toggle">
                <div className="topnav" id="mytopnav">
                        <br/>
                        <ul className='topnav'>
                            <li className='navigation_li' style={{background: "#3d0863"}}> <a href="/Homepage"> <HiOutlineHome/> Home</a></li>
                            <li className='navigation_li'><a href="/CryptoWorld"> <HiOutlineCurrencyDollar/> Crypto World</a></li>
                            <li className='navigation_li'><a  href="/TokenFactory"> <HiOutlineHomeModern/> Token Factory</a></li>  
                            <li className='navigation_li'><a href="/NFTFactory"> <HiOutlineHomeModern/> NFT Factory</a></li>
                            <li className='navigation_li'><a href="/CryGIA_Marketplace"> <HiOutlineUserGroup/> NFT Marketplace-CryGIA</a></li>
                            <li className='navigation_li'><a href="/CryptoCocktailParty"><HiOutlineTrophy/> Cocktail Party</a></li>
                            <li className='navigation_li'><a  href="/ContactUs"><HiEnvelope/> Contact Us</a></li> 
                        </ul>
                  </div>
              </div>
        )}
      <button onClick={toggle_NavigationBar} className="factoryTitle"> <HiBarsArrowUp/>
        { 'Collapse NavBar'} 
      </button>


      <h1 style = {{fontSize: '52px', textAlign:'center'}}> Legals</h1>
      <h3> Last update: 7th November 2024</h3>
      <div >
        <ul>
            <li><button onClick={toggle_setopenImpressumEN} className="legalsToggle"> { 'Legal Notice (English)'} <HiBarsArrowUp/> </button></li>
            <li><button onClick={toggle_setopenImpressumDE} className="legalsToggle"> { 'Impressum (German)'} <HiBarsArrowUp/> </button></li>
            <li><button onClick={toggle_setopenAGBEN} className="legalsToggle"> { 'Terms and Conditions (English)'} <HiBarsArrowUp/> </button></li>
            <li><button onClick={toggle_setopenAGBDE} className="legalsToggle"> { 'AGBs (German)'} <HiBarsArrowUp/> </button></li>
            <li><button onClick={toggle_setopenDatenschutzEN} className="legalsToggle"> { 'Privacy Policy (English)'} <HiBarsArrowUp/> </button></li>
            <li><button onClick={toggle_setopenDatenschutzDE} className="legalsToggle"> { 'Datenschutzerklärung (German)'} <HiBarsArrowUp/> </button></li>
            <li><button onClick={toggle_setopenCookiesPolicyEN} className="legalsToggle"> { 'Cookies Policy (English)'} <HiBarsArrowUp/> </button></li>
            <li><button onClick={toggle_setopenCookiesPolicyDE} className="legalsToggle"> { 'Cookies Richtlinien (German)'} <HiBarsArrowUp/> </button></li>
        </ul>
      </div>
      <br/>
      <br/>

      <div className="legalsText">
        {openImpressumEN && (<div> <ImpressumEN/> <button onClick={toggle_setopenImpressumEN} className="legalsToggle"> { 'Close - Legal Notice (English)'} <HiBarsArrowUp/> </button></div>)}
        {openImpressumDE && (<div> <ImpressumDE/> <button onClick={toggle_setopenImpressumDE} className="legalsToggle"> { 'Close - Impressum (German)'} <HiBarsArrowUp/> </button></div>)}
        {openAGBEN && (<div> <AgbEN/> <button onClick={toggle_setopenAGBEN} className="legalsToggle"> { 'Close - Terms and Conditions (English)'} <HiBarsArrowUp/> </button></div>)}
        {openAGBDE && (<div> <AgbDE/> <button onClick={toggle_setopenAGBDE} className="legalsToggle"> { 'Close - AGBs (German)'} <HiBarsArrowUp/> </button></div>)}
        {openDatenschutzEN && (<div> <DatenschutzEN/> <button onClick={toggle_setopenDatenschutzEN} className="legalsToggle"> { 'Close - Privacy Policy (English)'} <HiBarsArrowUp/> </button></div>)}
        {openDatenschutzDE && (<div> <DatenschutzDE/> <button onClick={toggle_setopenDatenschutzDE} className="legalsToggle"> { 'Close - Datenschutzerklärung (German)'} <HiBarsArrowUp/> </button></div>)}
        {openCookiesPolicyEN && (<div> <CookiesPolicyEN/> <button onClick={toggle_setopenCookiesPolicyEN} className="legalsToggle"> { 'Close - Cookies Policy (English)'} <HiBarsArrowUp/> </button></div>)}
        {openCookiesPolicyDE && (<div> <CookiesPolicyDE/> <button onClick={toggle_setopenCookiesPolicyDE} className="legalsToggle"> { 'Close - Cookies Richtilinien (German)'} <HiBarsArrowUp/> </button></div>)}
      </div>

      <br/>
      <br/>
      <br/>
      <br/>

      
        <div id="EndPage">
        <div style={{ borderTop: "2px solid #fff ", marginLeft: 20, marginRight: 20 }}></div>
        <p style={{textAlign: "center"}}>Copyright © 2024 CryptoBartl</p>
        </div>
      

    </div>
  </main>




  ) 
  
}

export default Legals;
