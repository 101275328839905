 export const CookiesPolicyEN = () => {
    return (
      
      <div >
        <h1>Cookies Policy</h1>
        <>
        <h3>1. DEFINITION OF COOKIES</h3>
        <p>Cookies are simple text files that are stored in users' browsers. They are used by websites to provide users with information 
        to provide better browsing experience and get traffic information. Cookies do not have the possibility of infecting the computer with viruses 
        or install malware. Cookies allow the server to offer users personalized pages, e.g. B. by remembering their login 
        and you don't have to re-enter it every time you visit a page.</p>
        <p>There are different types of cookies for different functions. None of them contain any personally identifiable information and are completely anonymous, 
        so that the website has no way of knowing users' personal data through cookies.</p>
        <p>The types of cookies are:</p>
        <ul>
        <li>Essential cookies: Some cookies are strictly necessary for browsing the websites. Without them, some parts of our
          Website not working properly. These cookies do not store any information about users' activities.</li>
          <li>Non-essential cookies: These cookies are not strictly necessary for correct navigation, but help to improve navigation and the
          to make collection of information about user activities simple and efficient.</li>
          <li>Functional cookies: These cookies are used to store users' preferences on our website in order to optimize it
          to enable navigation. They store and enable information about the decisions made by users
          Personalization of the website according to the needs of each individual. It also prevents users from every time they
          visit our websites, re-enter information or start the session.</li>
          <li>Performance cookies: These cookies are used to monitor the general usage of our website. 
          For this purpose, CryptoBartl uses Google Analytics from Google Inc., which records, among other things, the number of page views, visits and unique users. 
          The data collected is used to analyze how people get to know CryptoBartl, how many users return, which ones are most visited 
          pages, etc. This information might be collected via the users' IP address, it does not allow for personal identification of individual users.</li>
        </ul>
        <p>The data collected helps CryptoBartl understand which categories and information are most interesting to the audience in order to provide a better service.</p>
      </>
      <>
        <br/>
        <h3>2. BEHAVIORAL ADVERTISING</h3>
        <p>CryptoBartl uses cookies to understand which advertising to different users based on the areas they visit and 
        their geographical location could be more relevant. The cookie from a reliable ad server is stored in users' browsers, 
        to show each visitor the most relevant advertising, both on the Company's pages and on third-party websites. 
        These cookies do not contain any personally identifiable information, they are completely anonymous. Without these cookies, advertising would 
        shown to each user may not match their interests.</p>
        <p>FIRST-PARTY COOKIES: These cookies are stored by CryptoBartl. They are used to store users' preferences within CryptoBartl's pages, for example:</p>
        <ul>
          <li>Identification of the user's login details so that they do not have to log in every time.</li>
          <li>Identification of the user's country to suggest a specific language, with data that may be of interest to you, and
          Identification of user preferences </li>
          <li>Occasional cookies for specific actions</li>
        </ul>
        <p>THIRD-PARTY COOKIES: These cookies are stored by various services used within CryptoBartl, for example:</p>
        <ul>
          <li>Social networks</li>
          <li>Feedback service on CryptoBartl</li>
          <li>Ad server</li>
          <li>Identification of anonymous user activity</li>
          <li>Controlling the performance of the website</li>
        </ul>
      </>
      <>
        <br/>
        <h3>3. COOKIES EXPIRY</h3>
        <p>Different cookies have different expiration periods. Some are weekly (e.g. pop-up ads), others have a longer lifespan (monitoring website performance).</p>
      </>
      <>
        <br/>
        <h3>4. COOKIES DELETION</h3>
        <p>If you would like to remove cookies from your internet browser, please follow these instructions:</p>
        <p>
        <a href="https://support.google.com/accounts/answer/32050?co=GENIE.Platform%253DDesktop&hl=en">Complete guide for Chrome</a>
        </p>
        <p>In Safari:<br></br>
        <a href="https://support.apple.com/en-us/105082">Complete guide for Safari</a>
      </p>
      <p>Im Internet Explorer:<br></br>
      <a href="https://support.microsoft.com/en-gb/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc">Complete guide for Internet Explorer</a>
      </p>
      <p>In Firefox:<br></br>
      <a href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US">Complete guide for Firefox</a>
      </p>
      </>
      <>
        <br/>
        <h3>5. COOKIE POLICY UPDATES</h3>
        <p>The CryptoBartl Cookie Policy is subject to updates. CryptoBartl encourages users to consult this policy as often as possible.</p>
        <p>In the event that CryptoBartl decides to change this privacy policy to adapt it to new legal requirements, we will comply with these changes  
        on the Website so that users are always aware of what type of information we collect, how we use it and under what circumstances we disclose it.</p>
      </>
      <>
        <br/>
        <h3>6. IP ADRESSES</h3>
        <p>CryptoBartl uploads your computer's IP addresses or location to the Internet in order to optimize the management of the system and avoid possible problems.
           We do not use IP address records to track your session or behavior on this website. IP addresses are used 
           to collect general traffic information. We will never attempt to identify individual users via their IP addresses.</p>
      </>
      <>
        <br/>
        <h3>7. LINKS TO OTHER WEBSITES</h3>
        <p>This privacy policy applies only to this website, excluding any external websites to which we may link content that we believe 
          that they may be of interest to our visitors. We try to guarantee that all of these external links lead to high quality pages. 
          However, given the nature of the Internet, we cannot guarantee the quality of all linked websites or accept responsibility for their content.</p>
      </>
      
        <br/>
        <p>This Policy is drafted in English and German. In case of conflict the German version shall be the binding version.</p>
        <br/>
      </div>
    )
};

export const CookiesPolicyDE = () => {
  return (
    
    <div >
      <h1>Cookies Richtlinie</h1>
      <>
        <h3>1. DEFINITION VON COOKIES</h3>
        <p>Cookies sind einfache Textdateien, die in den Browsern der Nutzer gespeichert werden. Sie werden von Webseiten verwendet, um den Nutzern ein 
        besseres Surferlebnis zu bieten und Verkehrsinformationen zu erhalten. Cookies haben nicht die Möglichkeit, den Computer mit Viren zu infizieren 
        oder Malware zu installieren. Cookies ermöglichen es dem Server, den Nutzern personalisierte Seiten anzubieten, z. B. indem er sich deren Login merkt 
        und sie nicht bei jedem Besuch einer Seite neu eingeben muss.</p>
        <p>Es gibt verschiedene Arten von Cookies für unterschiedliche Funktionen. Keines von ihnen enthält persönlich identifizierbare Informationen und ist völlig anonym, 
        so dass die Website keine Möglichkeit hat, die persönlichen Daten der Nutzer durch Cookies zu erfahren.</p>
        <p>Die Arten von Cookies sind:</p>
        <ul>
          <li>Unerlässliche Cookies: Einige Cookies sind für das Surfen auf den Webseiten unbedingt erforderlich. Ohne sie könnten einige Teile unserer
          Website nicht richtig funktionieren. Diese Cookies speichern keine Informationen über die Aktivitäten der Nutzer.</li>
          <li>Nicht-essentielle Cookies: Diese Cookies sind für die korrekte Navigation nicht unbedingt erforderlich, helfen aber, die Navigation und die
          Sammlung von Informationen über die Nutzeraktivitäten einfach und effizient zu gestalten.</li>
          <li>Funktionale Cookies: Diese Cookies werden verwendet, um die Präferenzen der Nutzer auf unserer Website zu speichern und so eine optimale
          Navigation zu ermöglichen. Sie speichern Informationen über die von den Nutzern getroffenen Entscheidungen und ermöglichen die
          Personalisierung der Webseite entsprechend den Bedürfnissen jedes Einzelnen. Außerdem wird verhindert, dass die Nutzer jedes Mal, wenn sie
          unsere Websites besuchen, Informationen erneut eingeben oder die Sitzung starten müssen.</li>
          <li>Performance-Cookies: Diese Cookies werden verwendet, um die allgemeine Nutzung unserer Website zu überwachen. 
          Zu diesem Zweck verwendet CryptoBartl Google Analytics von Google Inc., das unter anderem die Anzahl der Seitenaufrufe, Besuche und eindeutigen Benutzer aufzeichnet. 
          Die gesammelten Daten werden verwendet, um zu analysieren, wie CryptoBartl kennengelernt wird, wie viele Nutzer zurückkehren, welches die am häufigsten besuchten 
          Seiten sind, usw. Diese Informationen werden über die IP-Adresse der Benutzer erfasst, was keine persönliche Identifizierung der einzelnen Benutzer ermöglicht.</li>
        </ul>
        <p>Die gesammelten Daten helfen CryptoBartl zu verstehen, welche Rubriken und Informationen für das Publikum am interessantesten sind, um so einen besseren Service bieten zu können.</p>
      </>
      <>
        <br/>
        <h3>2. VERHALTENSBEZOGENE WERBUNG</h3>
        <p>CryptoBartl verwendet Cookies, um zu verstehen, welche Werbung für verschiedene Nutzer auf der Grundlage der von ihnen besuchten Bereiche und 
        ihres geografischen Standorts relevanter sein könnte. Das Cookie eines zuverlässigen Ad-Servers wird in den Browsern der Nutzer gespeichert, 
        um jedem Besucher die relevanteste Werbung zu zeigen, sowohl auf den Seiten des Unternehmens als auch auf den Websites Dritter. 
        Diese Cookies enthalten keine persönlich identifizierbaren Informationen, sie sind völlig anonym. Ohne diese Cookies würde die Werbung, 
        die jedem Nutzer angezeigt wird, möglicherweise nicht seinen Interessen entsprechen.</p>
        <p>FIRST-PARTY COOKIES: Diese Cookies werden von CryptoBartl gespeichert. Sie werden verwendet, um die Präferenzen der Benutzer innerhalb der Seiten von CryptoBartl zu speichern, zum Beispiel:</p>
        <ul>
          <li>Identifizierung der Anmeldedaten des Benutzers, so dass er sich nicht jedes Mal neu anmelden muss.</li>
          <li>Identifizierung des Landes des Benutzers, um eine bestimmte Sprache vorzuschlagen, mit Daten, die für Sie von Interesse sein können, und
          Identifizierung der Benutzerpräferenzen </li>
          <li>Gelegentliche Cookies für bestimmte Aktionen</li>
        </ul>
        <p>THIRD-PARTY COOKIES: Diese Cookies werden von verschiedenen Diensten gespeichert, die innerhalb von CryptoBartl genutzt werden, zum Beispiel:</p>
        <ul>
          <li>Soziale Netzwerke</li>
          <li>Feedback-Dienst auf CryptoBartl</li>
          <li>Ad-Server</li>
          <li>Identifizierung der anonymen Nutzeraktivität</li>
          <li>Kontrolle der Leistung der Webseite</li>
        </ul>
      </>
      <>
        <br/>
        <h3>3. ABLAUF DER COOKIES</h3>
        <p>Verschiedene Cookies haben unterschiedliche Ablauffristen. Einige sind wöchentlich (z. B. Pop-up-Anzeigen), andere haben eine längere Lebensdauer (Kontrolle der Webseitenleistung).</p>
      </>
      <>
        <br/>
        <h3>4. LÖSCHUNG VON COOKIES</h3>
        <p>Wenn Sie die Cookies in Ihrem Internet-Browser entfernen möchten, folgen Sie bitte diesen Anweisungen:</p>
        <p>
          <a href="https://support.google.com/accounts/answer/32050?co=GENIE.Platform%253DDesktop&hl=en">Vollständige Anleitung für Chrome</a>
        </p>
        <p>In Safari:<br></br>
          <a href="https://support.apple.com/en-us/105082">Vollständige Anleitung für Safari</a>
        </p>
        <p>Im Internet Explorer:<br></br>
          <a href="https://support.microsoft.com/en-gb/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc">Vollständige Anleitung für Internet Explorer</a>
        </p>
        <p>In Firefox:<br></br>
          <a href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US">Vollständige Anleitung für Firefox</a>
        </p>
      </>
      <>
        <br/>
        <h3>5. UPDATES DER COOKIE-RICHTLINIE</h3>
        <p>Die CryptoBartl-Richtlinie zur Verwendung von Cookies ist anfällig für Aktualisierungen. CryptoBartl empfiehlt den Nutzern, diese Richtlinie so oft wie möglich zu konsultieren.</p>
        <p>Für den Fall, dass CryptoBartl beschließt, diese Datenschutzerklärung zu ändern, um sie an neue gesetzliche Anforderungen anzupassen, werden wir diese Änderungen  
        auf der Website wiedergeben, damit die Nutzer jederzeit wissen, welche Art von Informationen wir sammeln, wie wir sie verwenden und unter welchen Umständen wir sie veröffentlichen.</p>
      </>
      <>
        <br/>
        <h3>6. IP ADRESSEN</h3>
        <p>CryptoBartl lädt die IP-Adressen bzw. den Standort Ihres Computers im Internet hoch, um die Verwaltung des Systems zu optimieren und mögliche Probleme zu vermeiden.
           Wir verwenden die IP-Adressenaufzeichnungen nicht, um Ihre Sitzung oder Ihr Verhalten auf dieser Website zu verfolgen. IP-Adressen werden verwendet, 
           um allgemeine Verkehrsinformationen zu sammeln. Wir werden niemals versuchen, einzelne Nutzer über ihre IP-Adressen zu identifizieren.</p>
      </>
      <>
        <br/>
        <h3>7. LINKS ZU ANDEREN WEBSEITEN</h3>
        <p>Diese Datenschutzerklärung gilt nur für diese Website, mit Ausnahme aller externen Websites, auf die wir Inhalte verlinken können, von denen wir glauben, 
          dass sie für unsere Besucher von Interesse sein könnten. Wir versuchen zu garantieren, dass alle diese externen Links zu qualitativ hochwertigen Seiten führen. 
          In Anbetracht der Natur des Internets können wir jedoch nicht für die Qualität aller verlinkten Webseiten garantieren oder die Verantwortung für deren Inhalt übernehmen.</p>
      </>
      <br/>
      <p>Diese Richtlinie ist in deutscher und englischer Sprache verfasst. Im Falle von Unstimmigkeiten ist die deutsche Fassung vorrangig.</p>
      <br/>
    </div>
  )
};
