export const ImpressumEN = () => {

  //represented by: [Company Name] [Address] [Phone Number] [Email Address]
  return (
    <div>
        <h1>Legal Notice</h1>
        <p>Represented by: CryptoBartl | Germany | cryptobartl-emb@outlook.com</p>
        <p>Responsible for Content: Elena-Maria Bartl</p>
        <br/>
        <p>This Legal Notice complies with the German laws under § 5 DDG and § 55 RStV.</p>
    </div>
  )
};

export const ImpressumDE = () => {

    /*Mara Mustermann
      Musterstraße 3
      77777 Musterstadt
      Musterland 
      Telefon: +49 80 123456, Fax: +49 80 123456*/
    return (
      <div> 
          <h1>Impressum</h1>
          <p>Elena-Maria Bartl | Deutschland </p>
          <p>E-Mail: cryptobartl-emb@outlook.com</p>
          <br/>
          <p>Plattform der EU-Kommission zur Online-Streitbeilegung: https://ec.europa.eu/consumers/odr</p>
          <p>Der Verkäufer ist weder verpflichtet noch bereit, an einem  Streitbeilegungsverfahren vor einer alternativen Streitbeilegungsstelle teilzunehmen.</p>
      </div>
    )
  };
